import { MainMenuCategory } from '../main-menu-category.enum';

export class CreditNav {
  public static item = {
    title: 'main-navigation.credit',
    testId: 'credit-nav-link',
    icon: '<img src="./assets/icons/line-icons/nav/colors/package.svg" width="24px" height="24px">',
    themedIcon: {},
    bold: true,
    type: MainMenuCategory.credit,
    children: [
      {
        title: 'main-navigation.credit',
        url: '/oes/credit',
        testId: 'credit-nav-link',
      },
    ]
  };
}
