import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from '@user/user.service';
import { map } from 'rxjs/operators';
import { ROLE_TYPE } from '@user/role-type';
import { FeatureToggleService } from '@global/feature-toggle/feature-toggle.service';

/*
* Redirects the User to SCC experience if they do not have access to any other page of the app.
* e.g.: ROLE_TYPE.DEVELOPER_CREDIT only.
* */
@Injectable({ providedIn: 'root' })
export class SccRedirectGuard implements CanActivate {
  constructor(
    private _userService: UserService,
    private _router: Router,
    private _featureToggleService: FeatureToggleService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    const isSccEnabled = this._featureToggleService.isFeatureEnabled('scc');

    if (!isSccEnabled) {
      return true;
    }

    return this._userService.getCurrentUser().pipe(
      map(user => {
        // Credit only user.
         if (user.hasRole(ROLE_TYPE.DEVELOPER_CREDIT) &&
           !user.hasRole(ROLE_TYPE.DEVELOPER_USER) &&
           !user.hasRole(ROLE_TYPE.FINANCE_USER) &&
           !user.hasRole(ROLE_TYPE.VENDOR_USER) &&
           !user.hasRole(ROLE_TYPE.DATA_ANALYST)) {
          this._router.navigate(['/oes/credit']);
          return false;
        }

        return true;
      }
    ));
  }
}
