import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from '@user/user.service';
import { map } from 'rxjs/operators';
import { ROLE_TYPE } from '@user/role-type';
import { FeatureToggleService } from '@global/feature-toggle/feature-toggle.service';

/*
* Does not allow to access SCC if user does not contain Developer Credit role.
* */
@Injectable({ providedIn: 'root' })
export class SccGuard implements CanActivate {
  constructor(
    private _userService: UserService,
    private _router: Router,
    private _featureToggleService: FeatureToggleService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    const isSccEnabled = this._featureToggleService.isFeatureEnabled('scc');

    if (!isSccEnabled) {
      return false;
    }

    return this._userService.getCurrentUser().pipe(
      map(user => {
          if (!user.hasRole(ROLE_TYPE.DEVELOPER_CREDIT)) {
            this._router.navigate(['/oes/home']);
            return false;
          }

          return true;
        }
      ));
  }
}
