// Angular
import {BrowserModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Inject, Injector, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe, HashLocationStrategy, LOCATION_INITIALIZED, LocationStrategy, registerLocaleData} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
// fr and pt will be recognized as fr-Fr and pt-Pt.
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
// 3rd party
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// OES
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-router.module';
import {OrganizationManagementModule} from '@organization-management/organization-management.module';
import {MainNavigationComponent} from './main-navigation/main-navigation.component';
import {UserProfileComponent} from './user/profile/user-profile.component';
import {HomeComponent} from './home/home.component';
import { TaskComponent } from './task/task.component';
import {TileGuideComponent} from './home/tile-guide/tile-guide.component';
import {environment} from '../environments/environment';
import {SharedModule} from '@shared/shared.module';
import {InternetStatusComponent} from './main-navigation/internet-status/internet-status.component';
import {GlobalErrorHandlerService} from './global/services/global-error-handler.service';
import {UserSettingComponent} from './main-navigation/user-setting/user-setting.component';
import {OrganizationUnsavedGuard} from '@organization-management/organization/shared/guard/organization-unsaved.guard';
import {UnsavedChangesDialogComponent} from './global/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {RefreshTokenComponent} from './global/refresh-token/refresh-token.component';
import {LanguageService} from '@global/services/language.service';
import {ProgramHomepageItemComponent} from './home/program-homepage-item/program-homepage-item.component';
import {TermsComponent} from '@global/terms-of-service-and-privacy-policy/terms/terms.component';
import {TermsOfServiceAndPrivacyPolicyComponent} from '@global/terms-of-service-and-privacy-policy/terms-of-service-and-privacy-policy.component';
import {TermsOfServiceComponent} from '@global/terms-of-service-and-privacy-policy/terms/terms-of-service/terms-of-service.component';
import {ConflictOfInterestPoliciesComponent} from '@global/terms-of-service-and-privacy-policy/terms/conflict-of-interest-policies/conflict-of-interest-policies.component';
import {PrivacyPolicyComponent} from '@global/terms-of-service-and-privacy-policy/terms/privacy-policy/privacy-policy.component';
import {RoleSelectorComponent} from './main-navigation/role-selector/role-selector.component';
import {FooterComponent} from './main-navigation/footer/footer.component';
import {SelectedRoleComponent} from './main-navigation/role-selector/selected-role/selected-role.component';
import {ClipboardModule} from 'ngx-clipboard';
import {SideNavigationComponent} from '@main-navigation/side-navigation/side-navigation.component';
import {OdysseyComponent} from './home/theme/odyssey/odyssey.component';
import {GbeBeninComponent} from './home/theme/gbe-benin/gbe-benin.component';
import {BeninComponent} from './home/theme/benin/benin.component';
import {PharesComponent} from './home/theme/phares/phares.component';
import {ReaComponent} from './home/theme/rea/rea.component';
import {RearefComponent} from './home/theme/rearef/rearef.component';
import {ReaeeiComponent} from './home/theme/reaeei/reaeei.component';
import {EcreeeComponent} from './home/theme/ecreee/ecreee.component';
import {AnperComponent} from './home/theme/anper/anper.component';
import {DbComponent} from './home/theme/db/db.component';
import {AserComponent} from './home/theme/aser/aser.component';
import {NercComponent} from './home/theme/nerc/nerc.component';
import {EssorComponent} from './home/theme/essor/essor.component';
import {CbComponent} from './home/theme/cb/cb.component';
import {SomalilandComponent} from './home/theme/somaliland/somaliland.component';
import {UefComponent} from './home/theme/uef/uef.component';
import {RensourceComponent} from './home/theme/rensource/rensource.component';
import {IfcComponent} from './home/theme/ifc/ifc.component';
import {EeuComponent} from './home/theme/eeu/eeu.component';
import {EarfComponent} from './home/theme/earf/earf.component';
import {ApmModule, ApmService} from '@elastic/apm-rum-angular';
import {JwtInterceptor} from '@global/services/jwt.interceptor';
import {CacheErrorInterceptor} from '@global/services/cache-error.interceptor';
import {PopupMessageComponent} from '@global/popup-message/popup-message.component';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {MetricComponent} from './home/dashboard/visualization/metric/metric.component';
import {ChartComponent} from './home/dashboard/visualization/chart/chart.component';
import {VisualizationComponent} from './home/dashboard/visualization/visualization.component';
import {PaopComponent} from './home/theme/paop/paop.component';
import {AberComponent} from './home/theme/aber/aber.component';
import {TchadComponent} from './home/theme/tchad/tchad.component';
import {FunaeComponent} from './home/theme/funae/funae.component';
import {OgefComponent} from './home/theme/ogef/ogef.component';
import {OmdfComponent} from './home/theme/omdf/omdf.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {DashboardComponent} from './home/dashboard/dashboard.component';
import {OdysseyPlatformComponent} from './home/dashboard/odyssey-platform/odyssey-platform.component';
import {A2eiComponent} from './home/theme/a2ei/a2ei.component';
import {EppComponent} from './home/theme/epp/epp.component';
import {BrilhoComponent} from './home/theme/brilho/brilho.component';
import { DreamEthiopiaComponent } from './home/theme/dream-ethiopia/dream-ethiopia.component';
import { OrgMetricsComponent } from './home/dashboard/org-metrics/org-metrics.component';
import { GbeCotedivoireComponent } from './home/theme/gbe-cotedivoire/gbe-cotedivoire.component';
import { SunAfricaComponent } from './home/theme/sun-africa/sun-africa.component';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';
import { CeiAfricaComponent } from './home/theme/ceiafrica/ceiafrica.component';
import { PowertrustComponent } from './home/theme/powertrust/powertrust.component';
import { RrealiberiaComponent } from './home/theme/rrealiberia/rrealiberia.component';
import { AmaderComponent } from './home/theme/amader/amader.component';
import { AmapComponent } from './home/theme/amap/amap.component';
import { RmiComponent } from './home/theme/rmi/rmi.component';
import { BurundiComponent } from './home/theme/burundi/burundi.component';
import { NgChartsModule } from 'ng2-charts';
import { SideNavigationSecondaryComponent } from './side-navigation-secondary/side-navigation-secondary.component';
import { NeaComponent } from './home/theme/nea/nea.component';
import { AvoRenewablesComponent } from './home/theme/avorenewables/avorenewables.component';
import { IpffComponent } from './home/theme/ipff/ipff.component';
import { EsmapHfeComponent } from './home/theme/esmap-hfe/esmap-hfe.component';
import { CpiComponent } from './home/theme/cpi/cpi.component';
import { AreneComponent } from './home/theme/arene/arene.component';
import { AgerComponent } from './home/theme/ager/ager.component';
import { DaresComponent } from './home/theme/dares/dares.component';
import { SpinnerInterceptor } from '@global/services/spinner.interceptor';
import { CompletedTaskComponent } from './task/completed-task/completed-task.component';
import { TdbGroupComponent } from './home/theme/tdb/tdbgroup.component';
import { ProenergiaComponent } from './home/theme/proenergia/proenergia.component';
import { AmadermaliComponent } from './home/theme/amadermali/amadermali.component';
import { FondsDecimComponent } from './home/theme/fondsdecim/fondsdecim.component';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localePt, 'pt');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// waiting for translations initialization
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        TaskComponent,
        CompletedTaskComponent,
        UserProfileComponent,
        MainNavigationComponent,
        PopupMessageComponent,
        TileGuideComponent,
        InternetStatusComponent,
        UserSettingComponent,
        UnsavedChangesDialogComponent,
        RefreshTokenComponent,
        ProgramHomepageItemComponent,
        TermsComponent,
        TermsOfServiceAndPrivacyPolicyComponent,
        TermsOfServiceComponent,
        ConflictOfInterestPoliciesComponent,
        PrivacyPolicyComponent,
        RoleSelectorComponent,
        FooterComponent,
        SelectedRoleComponent,
        SideNavigationComponent,
        OdysseyComponent,
        GbeBeninComponent,
        GbeCotedivoireComponent,
        BeninComponent,
        PharesComponent,
        IpffComponent,
        CpiComponent,
        ProenergiaComponent,
        AmadermaliComponent,
        TdbGroupComponent,
        FondsDecimComponent,
        DaresComponent,
        EsmapHfeComponent,
        DreamEthiopiaComponent,
        CeiAfricaComponent,
        PowertrustComponent,
        RrealiberiaComponent,
        AvoRenewablesComponent,
        BrilhoComponent,
        EppComponent,
        ReaComponent,
        RearefComponent,
        ReaeeiComponent,
        EcreeeComponent,
        AnperComponent,
        DbComponent,
        AserComponent,
        NercComponent,
        EssorComponent,
        CbComponent,
        SomalilandComponent,
        NeaComponent,
        AmaderComponent,
        BurundiComponent,
        AmapComponent,
        AgerComponent,
        RmiComponent,
        SunAfricaComponent,
        TchadComponent,
        FunaeComponent,
        OgefComponent,
        OmdfComponent,
        A2eiComponent,
        PaopComponent,
        AberComponent,
        AreneComponent,
        UefComponent,
        RensourceComponent,
        IfcComponent,
        EeuComponent,
        EarfComponent,
        DashboardComponent,
        MetricComponent,
        ChartComponent,
        VisualizationComponent,
        OdysseyPlatformComponent,
        OrgMetricsComponent,
        PageNotFoundComponent,
        SideNavigationSecondaryComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [ApmModule,
        ApolloModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.serverUri, environment.dataServerUri, environment.analytics],
                sendAccessToken: true
            }
        }),
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            },
            isolate: false
        }),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        OrganizationManagementModule,
        ClipboardModule], providers: [
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useFactory: (languageService) => languageService.getLanguage()
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        OrganizationUnsavedGuard,
        DatePipe,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheErrorInterceptor,
            multi: true
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: `${environment.dataServerUri}/graphql`
                    }),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'no-cache',
                            errorPolicy: 'ignore',
                        },
                        query: {
                            fetchPolicy: 'no-cache',
                            errorPolicy: 'all',
                        }
                    }
                };
            },
            deps: [HttpLink]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        Title,
        {
            provide: ApmService,
            useClass: ApmService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
