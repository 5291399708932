import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-fondsdecim',
  templateUrl: './fondsdecim.component.html',
  styleUrls: ['./fondsdecim.component.scss']
})
export class FondsDecimComponent {

  constructor() { }

}
