import { AnalyticsNav } from '@main-navigation/shared/navi-item/role/analytics.constant';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeveloperNav } from '@main-navigation/shared/navi-item/role/developer-nav.constant';
import { environment } from '@environments/environment';
import { FinanceDeveloperNoProfilesNav } from '@main-navigation/shared/navi-item/finance-developer-no-profiles.constant';
import { InvestorNav } from '@main-navigation/shared/navi-item/role/investor-nav.constant';
import { KlaviyoService, MAIN_NAVIGATION_CHANGE } from '@shared/services/klaviyo.service';
import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { ProcurementNav } from '@main-navigation/shared/navi-item/procurement.constant';
import { ProjectNav } from '@main-navigation/shared/navi-item/project.constant';
import { ROLE_TYPE } from '@user/role-type';
import { RoleCheckService } from '@main-navigation/shared/role-check.service';
import { Router, NavigationEnd } from '@angular/router';
import { SideNavigationService } from './side-navigation.service';
import { Subject } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { UrlCategory } from '@main-navigation/shared/url-category.model';
import { UrlCheckService } from '@main-navigation/shared/url-check.service';
import { UserService } from '@user/user.service';
import { VendorNav } from '@main-navigation/shared/navi-item/role/vendor-nav.constant';
import { CommercialInvestorDemoNav } from '@main-navigation/shared/navi-item/role/commercial-investor-demo-nav.constant';
import { User } from '@user/user.model';
import { Theme } from '@shared/theme.enum.';
import { ThemesService } from '@shared/themes.service';
import { NavHeaderService, PinnedTabMapping } from 'src/app/nav-header/nav-header.service';
import { CrmNav } from '@main-navigation/shared/navi-item/role/crm-nav.constant';
import { FeatureToggleService } from '@global/feature-toggle/feature-toggle.service';

@Component({
  selector: 'oes-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  currentUrl: string | null;
  displayPoweredByIcon: boolean = false;
  menuItems: NavItem[] = [];
  open = false;
  pin = false;
  pinnedTabs: PinnedTabMapping;
  profileIsCompleted: boolean = false;
  roleType = ROLE_TYPE;
  selectedRole: ROLE_TYPE;
  standardNav: boolean = true;
  svgStrokeColor: string;
  theme: string;
  tooltipStyle = {};
  tooltipText: string;
  tooltipIsShowing: boolean = false;
  user: User;

  private featureToggles: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private _featureToggleService: FeatureToggleService,
    private _klaviyoService: KlaviyoService,
    private _navHeaderService: NavHeaderService,
    private _roleCheckService: RoleCheckService,
    private _router: Router,
    private _sideNavigationService: SideNavigationService,
    private _themesService: ThemesService,
    private _urlCheckService: UrlCheckService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.findTheme();
    this.userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.subscribeMenuItem();
    this.subscribeRouter();
    this.subscribeUserRole();
    this.subscribePinnedTabs();
    this.getFeatureToggles();
    this.loadMenuItem(true);
    this.subscribeCurrentUrl();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    // initialize for the next login
    this._sideNavigationService.navItems = [];
  }

  private findTheme() {
    this._themesService.themeObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((theme: Theme) => {
        this.theme = theme;
        this.displayPoweredByIcon = theme !== Theme.ODYSSEY ? true : false;
      });
  }

  private subscribeRouter() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((next) => {
        if (next) {
          this.sendNavigationChangeToKlaviyo();
        }
      });
  }

  private subscribeMenuItem() {
    this._sideNavigationService.navItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((menuItems: NavItem[]) => {
        this.menuItems = menuItems;
      });
  }

  private subscribeUserRole() {
    this.userService.userRole$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((role: ROLE_TYPE) => {
        if (role && this.selectedRole !== role) {
          this.selectedRole = role;
          this.loadMenuItem(false);
        }
      });
  }

  private subscribePinnedTabs() {
    this._navHeaderService.pinnedTabs$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((obj: PinnedTabMapping) => {
        if (obj) {
          this.pinnedTabs = obj;
        }
      });
  }

  private subscribeCurrentUrl() {
    this._sideNavigationService.currentUrl$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((url: string) => {
        this.resolveInitialMenuItem(url);
      });
  }

  getChild(navItem: NavItem): NavItem {
    const idx = this.pinnedTabs ? this.pinnedTabs[navItem?.type] : null;
    if (idx && idx < navItem?.children?.length) {
      return navItem?.children[idx];
    }
    return navItem.children[0];
  }

  private resolveInitialMenuItem(url: string) {
    const baseUrl = url.split('?')[0];
    const currentNavItem = this.menuItems.find((item: NavItem) => {
      if (item.children) {
        return item.children.find((child: NavItem) => {
          return child.url === baseUrl;
        });
      }
    });
    if (currentNavItem) {
      this._sideNavigationService.currentNavItem = currentNavItem;
    }
  }

  isRouteActive(url: string): boolean {
    const currentUrlSegments = this._router.url.split('/');
    const linkUrlSegments = url.split('/');
    if (currentUrlSegments.length < 3 || linkUrlSegments.length < 3) {
      return false;
    }
    return currentUrlSegments[2] === linkUrlSegments[2];
  }

  private loadMenuItem(isFirstTime: boolean) {
    const result: UrlCategory = this._urlCheckService.detectUrl();
    if (this.selectedRole && result) {
      this._sideNavigationService.loadCompleted = false; // joinParentPath will set true
      this.standardNav = result.category === undefined ? true : false;
      if ((this.standardNav && this.selectedRole) || isFirstTime) {
        this.selectMenuByRole();
        this._sideNavigationService.loadCompleted = true;
      }
    }
  }

  private selectMenuByRole() {
    switch (this.selectedRole) {
      case ROLE_TYPE.DEVELOPER_USER: {
        let items = DeveloperNav.item(this.userService.isDeveloperAndCreditUser);
        if (location.host.includes('earf.')) {
          items = [
            ProjectNav.item,
            FinanceDeveloperNoProfilesNav.item,
            ProcurementNav.item,
            AnalyticsNav.item,
          ];
        }
        this.menuItems = this._roleCheckService.check(items);
        break;
      }
      case ROLE_TYPE.FINANCE_USER: {
        const demoUser = this.userService.hasRole(
          ROLE_TYPE.COMMERCIAL_INVESTOR_DEMO_USER
        );
        if (demoUser) {
          this.menuItems = this._roleCheckService.check(
            CommercialInvestorDemoNav.item
          );
        } else {
          this.menuItems = this._roleCheckService.check(InvestorNav.item);
        }
        break;
      }
      case ROLE_TYPE.VENDOR_USER:
        this.menuItems = this._roleCheckService.check(VendorNav.item);
        break;
      case ROLE_TYPE.SYSTEM_ADMIN:
        this.menuItems = [AnalyticsNav.item];
        break;
      case ROLE_TYPE.DATA_ANALYST:
        this.menuItems = [AnalyticsNav.item];
        break;
      case ROLE_TYPE.ORGANIZATION_ADMIN:
        this.menuItems = [];
        break;
    }
    this.menuItems.forEach((item) => (item['selectedParent'] = true));
    // During POC phase activate CRM menu for all system admins
    if (this.user.hasRole(ROLE_TYPE.SYSTEM_ADMIN) && this.featureToggles?.crm) {
      this.menuItems.push(CrmNav.item);
    }
  }

  // TODO: can be moved to NavigationEnd of router.subscription
  public sendNavigationChangeToKlaviyo() {
    if (environment.name !== 'dev') {
      setTimeout(() => {
        const location = window.location.hash;
        this._klaviyoService.sendMetric(MAIN_NAVIGATION_CHANGE, {
          location,
        });
      }, 200);
    }
  }

  public handleClick(e, navItem: NavItem, idx: number) {
    if (navItem) {
      this._sideNavigationService.currentNavItem = navItem;
    }
  }

  public showTooltip = (e: MouseEvent, element: HTMLElement, text: string) => {
    const rect = element.getBoundingClientRect();
    this.tooltipStyle = {
      top: `${rect.top + 7}px`,
    };
    this.tooltipText = text;
    this.tooltipIsShowing = true;
  };

  public hideTooltip = () => {
    this.tooltipIsShowing = false;
  };

  getFeatureToggles() {
    this.featureToggles = this._featureToggleService.getFeatureTogglesAsObject();
  }
}
