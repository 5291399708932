import { ProjectNav } from '../project.constant';
import { ProcurementNav } from '../procurement.constant';
import { FinanceDeveloperNav } from '../finance-developer.constant';
import { NavItem } from '../nav-item.model';
import { OperateNav } from './operate-nav.constant';
import { AnalyticsNav } from './analytics.constant';
import { CreditNav } from '@main-navigation/shared/navi-item/credit.constants';

export class DeveloperNav {
  public static item(creditEnabled?: boolean): NavItem[] {
    const dynamicNavItem = creditEnabled ? CreditNav.item : ProcurementNav.item;

    return [
      ProjectNav.item,
      FinanceDeveloperNav.item,
      dynamicNavItem,
      OperateNav.item,
      AnalyticsNav.item
    ];
  }
}
